define('date-range-picker/pods/year-display/component', ['exports', 'date-range-picker/pods/year-display/template', 'date-range-picker/helpers/range', 'moment'], function (exports, _dateRangePickerPodsYearDisplayTemplate, _dateRangePickerHelpersRange, _moment) {
  'use strict';

  var computed = Ember.computed,
      Component = Ember.Component,
      run = Ember.run;

  exports['default'] = Component.extend({
    allYearsOffset: 10,
    isExpanded: false,
    layout: _dateRangePickerPodsYearDisplayTemplate['default'],
    tagName: "span",
    month: (0, _moment['default'])(),
    energyYear: false,

    didRender: function didRender() {
      var _this = this;

      if (this.get('isExpanded')) {
        run.next(this, function () {
          var year = _this._deduceYear();
          var $container = _this.$('.dp-year-body');
          var $scrollTo = _this.$('button.dp-btn-year-option:contains(' + year + ')');
          if ($container && $container.length && $scrollTo && $scrollTo.length) {
            $container.scrollTop($scrollTo.offset().top - $container.offset().top + $container.scrollTop());
            $container.animate({
              scrollTop: $scrollTo.offset().top - $container.offset().top + $container.scrollTop()
            }, 0);
          }
        });
      }
    },
    _deduceYear: function _deduceYear() {
      if (this.get('insideYearPicker') && this.get('startDate')) {
        this.get('startDate').year();
      }
      var month = this.get('month');
      month ? month.year() : (0, _moment['default'])().year();
    },

    allYears: computed('startDate', 'insideYearPicker', 'allYearsOffset', 'month', function () {
      var year = this.get('insideYearPicker') ? this.get('startDate').year() : this.get('month').year();
      var offset = this.get('allYearsOffset');

      return (0, _dateRangePickerHelpersRange.range)(year - offset, year + offset + 1);
    }),

    setCalendarYear: function setCalendarYear(year) {
      var day = this.get('startDate').date();
      var month = this.get('startDate').format("MM");
      var startDate = (0, _moment['default'])(year + '-' + month + '-' + day, 'YYYY-MM-DD');
      var endDate = (0, _moment['default'])(year, "YYYY").endOf('year');

      if (this.get('ignoreMonthAndDay')) {
        startDate = (0, _moment['default'])(year, "YYYY").startOf('year');
      }

      if (!this.get('onlyUpdateMonth')) {
        this.set('endDate', endDate);
        this.set('startDate', startDate);
      } else {
        var monthDay = this.get('month').date();
        var monthMonth = this.get('month').format("MM");
        var newMonth = (0, _moment['default'])(year + '-' + monthMonth + '-' + monthDay, 'YYYY-MM-DD');

        this.set('month', newMonth);
      }

      if (this.get('yearWasSelected')) {
        this.sendAction('yearWasSelected');
      }
    },
    setEnergyYear: function setEnergyYear(year) {
      this.set('startDate', (0, _moment['default'])(year - 1 + '-' + 6 + '-' + 1, 'YYYY-MM-DD'));
      this.set('endDate', (0, _moment['default'])(year + '-' + 5 + '-' + 31, 'YYYY-MM-DD'));
    },

    actions: {
      setYear: function setYear(year) {
        if (this.get('energyYear')) {
          this.setEnergyYear(year);
        } else {
          this.setCalendarYear(year);
        }
      },
      toggleIsExpanded: function toggleIsExpanded() {
        this.toggleProperty('isExpanded');
      }
    }
  });
});