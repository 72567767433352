define("date-range-picker/mixins/picker", ["exports", "date-range-picker/mixins/cancelable", "date-range-picker/mixins/safe-moment", "moment"], function (exports, _dateRangePickerMixinsCancelable, _dateRangePickerMixinsSafeMoment, _moment) {
  "use strict";

  var _slicedToArray = (function () {
    function sliceIterator(arr, i) {
      var _arr = [];var _n = true;var _d = false;var _e = undefined;try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;_e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }return _arr;
    }return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  })();

  var Mixin = Ember.Mixin;

  exports["default"] = Mixin.create(_dateRangePickerMixinsCancelable["default"], _dateRangePickerMixinsSafeMoment["default"], {
    showInput: true,
    dateFormat: "MM/DD/YYYY",
    tabIndex: 1,
    showClear: true,
    startDate: (0, _moment["default"])().startOf('date'),
    endDate: (0, _moment["default"])().startOf('date'),
    startMonth: (0, _moment["default"])().startOf('month'),
    endMonth: (0, _moment["default"])().startOf('month'),
    defaultStart: 'date',
    defaultEnd: 'date',

    dropdownController: Ember.Object.create({
      isOpen: false
    }),
    initiallyOpened: false,

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var startDate = this.get('startDate');

      if (typeof startDate === 'string') {
        startDate = this.set('startDate', (0, _moment["default"])(startDate));
      }

      var endDate = this.get('endDate');
      if (typeof endDate === 'string') {
        endDate = this.set('endDate', (0, _moment["default"])(endDate));
      }

      if (!this.get('initialStartDate')) {
        this.resetInitialValues();
      }

      this.updateStartAndEndMonth();
    },

    updateStartAndEndMonth: function updateStartAndEndMonth() {
      this.setProperties({
        startMonth: this.safeClone('startDate', (0, _moment["default"])().startOf('month')),
        endMonth: this.safeClone('endDate', (0, _moment["default"])().startOf('month'))
      });
    },

    rangeFormatted: Ember.computed('startDate', 'endDate', 'dateFormat', {
      get: function get() {
        var dateFormat = this.get('dateFormat');
        var startDate = this.get('startDate') ? this.get('startDate').format(dateFormat) : '';
        var endDate = this.get('endDate') ? this.get('endDate').format(dateFormat) : '';
        return startDate + "—" + endDate;
      },
      set: function set(k, v) {
        var _v$split = v.split('—'),
            _v$split2 = _slicedToArray(_v$split, 2),
            start = _v$split2[0],
            end = _v$split2[1];

        var dateFormat = this.get('dateFormat');
        var startMoment = (0, _moment["default"])(start, dateFormat);
        var endMoment = (0, _moment["default"])(end, dateFormat);

        if (this.get('hasDateParseOverride')) {
          startMoment = this.overrideStartDateParse(startMoment);
          endMoment = this.overrideEndDateParse(endMoment);
        }

        if (startMoment.isAfter(endMoment)) {
          var _ref = [endMoment.clone(), startMoment.clone()];
          startMoment = _ref[0];
          endMoment = _ref[1];
        }

        if (startMoment && startMoment.isValid()) {
          this.set('startDate', startMoment);
        } else {
          this.set('startDate', null);
        }

        if (endMoment && endMoment.isValid()) {
          this.set('endDate', endMoment);
        } else {
          this.set('endDate', null);
        }

        this.updateStartAndEndMonth();

        return v;
      }
    }),

    focusOnInput: function focusOnInput() {
      this.$('.dp-date-input').first().focus().select();
    },

    actions: {
      registerAPI: function registerAPI(context) {
        this.set('dropdownController', context);
        this.sendAction('dropdownContextChanged', context);
      },
      open: function open() {
        var dropdown = this.get('dropdownController');
        if (dropdown) {
          dropdown.actions.open();
        }
      },
      apply: function apply() {
        this.resetInitialValues();

        var dropdown = this.get('dropdownController');
        if (dropdown) {
          dropdown.actions.close(null, false);
        }
        this.sendAction('apply', this.get('startDate'), this.get('endDate'));
      },
      onFocusInput: function onFocusInput(dropdown, e) {
        if (e && e.relatedTarget && e.relatedTarget.className && (e.relatedTarget.className.includes('dp-apply') || e.relatedTarget.className.includes('dp-cancel') || e.relatedTarget.className.includes('dp-date-input'))) {
          return true;
        }

        dropdown.actions.open(e);
        this.focusOnInput();
      },
      onFocusOut: function onFocusOut(dropdown, e) {
        if (e && e.relatedTarget && this.$() && this.$().get(0).contains(e.relatedTarget)) {
          return true;
        }

        this.send('apply');
      },
      handleKeydown: function handleKeydown(dropdown, e) {
        if (e.keyCode === 9 && dropdown.isOpen) {
          // Tab
          this.send('cancel');
        } else if (e.keyCode === 13) {
          //enter pressed when closed
          if (this.get('dropdownOpen')) {
            if (!this.get('datesSame')) {
              if (this.get('cancelSelected')) {
                this.send('cancel');
              } else {
                this.send('apply');
              }
            }
          } else {
            this.get('dropdownController').actions.toggle();
            if (this.get('dropdownOpen')) {
              this.focusOnInput();
            }
          }
        }
        return false;
      }
    }
  });
});