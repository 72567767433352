define('ember-spectrum-color-picker/components/spectrum-color-picker', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend({
    classNames: 'spectrum-color-picker',

    containerClassName: 'spectrum-color-picker-container',

    replacerClassName: 'spectrum-color-picker-replacer',

    tagName: 'input',

    color: null,

    flatMode: false,

    allowEmpty: false,

    disabled: false,

    showInput: false,

    showAlpha: false,

    showInitial: false,

    showButtons: true,

    showPalette: false,

    showPaletteOnly: false,

    palette: [],

    togglePaletteOnly: false,

    showSelectionPalette: false,

    maxSelectionSize: 7,

    hideAfterPaletteSelect: false,

    preferredFormat: null,

    moveFiresChange: false,

    clickoutFiresChange: false,

    chooseText: 'Choose',

    cancelText: 'Cancel',

    togglePaletteMoreText: 'More',

    togglePaletteLessText: 'Less',

    appendTo: 'body',

    localStorageKey: 'spectrum-color-picker',

    updatePicker: _ember['default'].observer('color', function () {
      this.$().spectrum('set', this.get('color'));
    }),

    updateDisabled: _ember['default'].observer('disabled', function () {
      this.$().spectrum(this.get('disabled') ? 'disable' : 'enable');
    }),

    didInsertElement: function didInsertElement() {
      var palette = this.get('palette');
      var opts = {
        color: this.get('color'),
        flat: this.get('flatMode'),
        containerClassName: this.get('containerClassName'),
        replacerClassName: this.get('replacerClassName'),
        allowEmpty: this.get('allowEmpty'),
        disabled: this.get('disabled'),
        showInput: this.get('showInput'),
        showAlpha: this.get('showAlpha'),
        showInitial: this.get('showInitial'),
        showButtons: this.get('showButtons'),
        showPalette: this.get('showPalette'),
        showPaletteOnly: this.get('showPaletteOnly'),
        palette: typeof palette === 'string' ? JSON.parse(palette) : palette,
        togglePaletteOnly: this.get('togglePaletteOnly'),
        showSelectionPalette: this.get('showSelectionPalette'),
        maxSelectionSize: this.get('maxSelectionSize'),
        hideAfterPaletteSelect: this.get('hideAfterPaletteSelect'),
        preferredFormat: this.get('preferredFormat'),
        clickoutFiresChange: this.get('clickoutFiresChange'),
        chooseText: this.get('chooseText'),
        cancelText: this.get('cancelText'),
        togglePaletteMoreText: this.get('togglePaletteMoreText'),
        togglePaletteLessText: this.get('togglePaletteLessText'),
        appendTo: this.get('appendTo'),
        localStorageKey: this.get('localStorageKey')
      };
      var self = this;
      var updateFunction = function updateFunction(newColor) {
        var color = newColor ? newColor.toString() : null;
        var onChange = self.get('onChange');

        if (!self.isDestroyed) {
          self.set('color', color);
        }

        if (onChange) {
          onChange(color);
        }
      };

      opts.change = updateFunction;

      if (this.get('moveFiresChange')) {
        opts.move = updateFunction;
      }

      // Move Event
      var onMove = self.get('onMove');
      if (onMove) {
        opts.move = function (newColor) {
          onMove(newColor ? newColor.toString() : null);
        };
      }

      // Hide Event
      var onHide = self.get('onHide');
      if (onHide) {
        opts.hide = function (newColor) {
          onHide(newColor ? newColor.toString() : null);
        };
      }

      // Show Event
      var onShow = self.get('onShow');
      if (onShow) {
        opts.show = function (newColor) {
          onShow(newColor ? newColor.toString() : null);
        };
      }

      this.$().spectrum(opts);
    },

    willDestroyElement: function willDestroyElement() {
      this.$().spectrum('destroy');
    }
  });
});