define('date-range-picker/pods/calendar-display/component', ['exports', 'date-range-picker/pods/calendar-display/template', 'date-range-picker/mixins/expanded-validators', 'date-range-picker/helpers/build-week', 'moment'], function (exports, _dateRangePickerPodsCalendarDisplayTemplate, _dateRangePickerMixinsExpandedValidators, _dateRangePickerHelpersBuildWeek, _moment) {
  'use strict';

  var computed = Ember.computed;

  exports['default'] = Ember.Component.extend(_dateRangePickerMixinsExpandedValidators['default'], {
    layout: _dateRangePickerPodsCalendarDisplayTemplate['default'],
    month: (0, _moment['default'])().startOf('month'),
    selectionEnd: null,
    selectionStart: null,

    calendarExpanded: computed('monthPickerExpanded', 'yearPickerExpanded', function () {
      return !this.get('monthPickerExpanded') && !this.get('yearPickerExpanded');
    }),

    weeks: computed('month', function () {
      var weekNumber = this.get('month').clone().startOf('month').week();
      var weeks = [];
      for (var i = 0; i <= 5; i++) {
        weeks[i] = (0, _dateRangePickerHelpersBuildWeek.buildWeek)(this.get('month').clone().week(weekNumber + i));
      }
      return weeks;
    }),

    actions: {
      daySelected: function daySelected(day) {
        this.sendAction('daySelected', day);
      },
      nextMonth: function nextMonth() {
        this.sendAction.apply(this, ['nextMonth'].concat(Array.prototype.slice.call(arguments)));
      },
      prevMonth: function prevMonth() {
        this.sendAction.apply(this, ['prevMonth'].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });
});