define('date-range-picker/mixins/cancelable', ['exports', 'moment', 'date-range-picker/mixins/safe-moment'], function (exports, _moment, _dateRangePickerMixinsSafeMoment) {
  'use strict';

  var computed = Ember.computed,
      run = Ember.run;

  exports['default'] = Ember.Mixin.create(_dateRangePickerMixinsSafeMoment['default'], {
    resetInitialValues: function resetInitialValues() {
      var _this = this;

      if (this.get('isDestroyed') || this.get('isDestroying')) {
        return;
      }

      this.setProperties({
        initialStartDate: this.safeClone('startDate'),
        initialEndDate: this.safeClone('endDate'),
        initialStartMonth: this.safeClone('startMonth', (0, _moment['default'])().startOf('month')),
        initialEndMonth: this.safeClone('endMonth', (0, _moment['default'])().startOf('month'))
      });

      run.next(this, function () {
        _this.notifyPropertyChange('initialStartDate');
        _this.notifyPropertyChange('initialStartMonth');
        _this.notifyPropertyChange('initialEndDate');
        _this.notifyPropertyChange('initialEndMonth');
      });
    },

    datesSame: computed('startDate', 'endDate', 'startMonth', 'endMonth', 'initialStartDate', 'initialEndDate', 'initialStartMonth', 'initialEndMonth', function () {
      return this.safeIsSame('startDate', 'initialStartDate') && this.safeIsSame('endDate', 'initialEndDate') && this.safeIsSame('startMonth', 'initialStartMonth') && this.safeIsSame('endMonth', 'initialEndMonth');
    }),

    actions: {
      reset: function reset() {
        if (this.get('isDestroyed') || this.get('isDestroying')) {
          return;
        }

        this.setProperties({
          startDate: this.safeClone('initialStartDate'),
          endDate: this.safeClone('initialEndDate'),
          startMonth: this.safeClone('initialStartMonth', (0, _moment['default'])().startOf('month')),
          endMonth: this.safeClone('initialEndMonth', (0, _moment['default'])().startOf('month'))
        });
      },
      cancel: function cancel() {
        this.send('reset');
        var dropdown = this.get('dropdownController');
        if (dropdown) {
          dropdown.actions.close(null, false);
        }
        this.sendAction('cancel');
      }
    }
  });
});