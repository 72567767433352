define('date-range-picker/mixins/keyboard-hotkeys', ['exports', 'ember-keyboard'], function (exports, _emberKeyboard) {
  'use strict';

  var on = Ember.on,
      Mixin = Ember.Mixin,
      computed = Ember.computed;

  exports['default'] = Mixin.create(_emberKeyboard.EKMixin, {
    dropdownOpen: computed.alias('dropdownController.isOpen'),
    keyboardActivated: computed.alias('dropdownOpen'),
    keyboardFirstResponder: computed.alias('dropdownOpen'),
    cancelSelected: true,
    applySelected: false,

    _returnHandler: on((0, _emberKeyboard.keyDown)('Enter'), function () {
      this.onTriggerReturn();
    }),

    _escapeHandler: on((0, _emberKeyboard.keyDown)('Escape'), function () {
      this.onTriggerEscape();
    }),

    _downArrowHandler: on((0, _emberKeyboard.keyDown)('ArrowDown'), function () {
      this.onTriggerArrowDown();
    }),

    _upArrowHandler: on((0, _emberKeyboard.keyDown)('ArrowUp'), function () {
      this.onTriggerArrowUp();
    }),

    toggleSelection: function toggleSelection() {
      this.toggleProperty('cancelSelected');
      this.toggleProperty('applySelected');
    },
    onTriggerArrowDown: function onTriggerArrowDown() {
      this.toggleSelection();
    },
    onTriggerArrowUp: function onTriggerArrowUp() {
      this.toggleSelection();
    },
    onTriggerEscape: function onTriggerEscape() {
      this.send('cancel');
    },
    onTriggerReturn: function onTriggerReturn() {
      if (this.get('dropdownOpen')) {
        if (!this.get('datesSame')) {
          if (this.get('cancelSelected')) {
            this.send('cancel');
          } else {
            this.send('apply');
          }
        }
      } else {
        this.get('dropdownController').actions.toggle();
        if (this.get('dropdownOpen')) {
          this.$('.dp-date-input').first().focus().select();
        }
      }
    }
  });
});