define("date-range-picker/helpers/range", ["exports"], function (exports) {
  "use strict";

  exports.range = range;

  function range(begin, end) {
    var res = [];

    if (end === undefined) {
      end = begin;
      begin = 0;
    }

    var asc = begin < end;

    for (var cur = begin, i = 0; asc ? cur < end : cur > end; asc ? cur++ : cur--, i++) {
      res[i] = cur;
    }

    return res;
  }

  exports["default"] = Ember.Helper.helper(range);
});