define('date-range-picker/pods/month-picker/component', ['exports', 'date-range-picker/pods/month-picker/template', 'date-range-picker/mixins/picker', 'date-range-picker/mixins/keyboard-hotkeys', 'date-range-picker/mixins/multiple-expanded-validators'], function (exports, _dateRangePickerPodsMonthPickerTemplate, _dateRangePickerMixinsPicker, _dateRangePickerMixinsKeyboardHotkeys, _dateRangePickerMixinsMultipleExpandedValidators) {
  'use strict';

  var run = Ember.run,
      Component = Ember.Component;

  exports['default'] = Component.extend(_dateRangePickerMixinsPicker['default'], _dateRangePickerMixinsMultipleExpandedValidators['default'], _dateRangePickerMixinsKeyboardHotkeys['default'], {
    classNames: ['dp-month-picker'],
    dateFormat: "MM/YYYY",
    layout: _dateRangePickerPodsMonthPickerTemplate['default'],
    defaultStart: 'month',
    defaultEnd: 'month',

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      run.next(this, function () {
        _this.notifyPropertyChange('startDate');
        _this.notifyPropertyChange('endDate');
      });
    }
  });
});