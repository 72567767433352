define('date-range-picker/mixins/safe-moment', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  exports['default'] = Ember.Mixin.create({
    safeIsSame: function safeIsSame(first, second) {
      var one = this.get(first);
      var two = this.get(second);
      if (!one || !two) {
        return false;
      }

      return one.isSame(two, 'day');
    },
    safeClone: function safeClone(date) {
      var defaultCloneValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

      if (!this.get(date)) {
        return defaultCloneValue;
      }
      return this.get(date).clone();
    }
  });
});