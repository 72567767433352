define('ember-click-outside/components/click-outside', ['exports', 'ember-click-outside/mixins/click-outside', 'ember-click-outside/templates/components/click-outside'], function (exports, _emberClickOutsideMixinsClickOutside, _emberClickOutsideTemplatesComponentsClickOutside) {
  'use strict';

  exports['default'] = Ember.Component.extend(_emberClickOutsideMixinsClickOutside['default'], {
    layout: _emberClickOutsideTemplatesComponentsClickOutside['default'],

    clickOutside: function clickOutside(e) {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }

      var exceptSelector = this.get('except-selector');
      if (exceptSelector && Ember.$(e.target).closest(exceptSelector).length > 0) {
        return;
      }

      var action = this.get('action');
      if (typeof action !== 'undefined') {
        action(e);
      }
    },

    _attachClickOutsideHandler: Ember.on('didInsertElement', function () {
      this._cancelOutsideListenerSetup = Ember.run.next(this, this.addClickOutsideListener);
    }),

    _removeClickOutsideHandler: Ember.on('willDestroyElement', function () {
      Ember.run.cancel(this._cancelOutsideListerSetup);
      this.removeClickOutsideListener();
    })
  });
});