define("date-range-picker/helpers/build-week", ["exports"], function (exports) {
  "use strict";

  exports.buildWeek = buildWeek;

  function buildWeek(week) {
    var days = [];
    for (var i = 0; i < 7; i++) {
      days[i] = week.clone().day(i);
    }
    return days;
  }

  exports["default"] = Ember.Helper.helper(buildWeek);
});