define('date-range-picker/pods/year-picker/component', ['exports', 'date-range-picker/pods/year-picker/template', 'date-range-picker/mixins/picker', 'date-range-picker/mixins/keyboard-hotkeys', 'date-range-picker/mixins/safe-moment', 'moment'], function (exports, _dateRangePickerPodsYearPickerTemplate, _dateRangePickerMixinsPicker, _dateRangePickerMixinsKeyboardHotkeys, _dateRangePickerMixinsSafeMoment, _moment) {
  'use strict';

  var computed = Ember.computed,
      run = Ember.run,
      Component = Ember.Component;

  exports['default'] = Component.extend(_dateRangePickerMixinsPicker['default'], _dateRangePickerMixinsKeyboardHotkeys['default'], _dateRangePickerMixinsSafeMoment['default'], {
    layout: _dateRangePickerPodsYearPickerTemplate['default'],
    dateFormat: "YYYY",
    defaultStart: 'year',
    defaultEnd: 'year',
    classNameBindings: ['energyYear:dp-energy-year-picker:dp-year-picker'],

    energyYear: false,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      run.next(this, function () {
        _this.notifyPropertyChange('startDate');
        _this.notifyPropertyChange('endDate');
      });
    },

    inputMask: computed('energyYear', function () {
      if (this.get('energyYear')) {
        return "EY 99[99]";
      } else {
        return "99[99]";
      }
    }),

    rangeFormatted: computed('startDate', 'endDate', 'dateFormat', 'energyYear', {
      get: function get() {
        var dateFormat = this.get('dateFormat');
        var date = this.get('endDate') ? this.get('endDate').format(dateFormat) : '';
        if (this.get('energyYear')) {
          return "EY " + date;
        } else {
          return date;
        }
      },
      set: function set() {
        this._super.apply(this, arguments);
      }
    }),

    hasDateParseOverride: computed('energyYear', function () {
      return this.get('energyYear');
    }),

    overrideStartDateParse: function overrideStartDateParse(startDate) {
      if (this.get('energyYear') && startDate && startDate.isValid()) {
        return (0, _moment['default'])(startDate.year() - 1 + '-' + 6 + '-' + 1, "YYYY-MM-DD");
      } else {
        return null;
      }
    },
    overrideEndDateParse: function overrideEndDateParse(endDate) {
      if (this.get('energyYear') && endDate && endDate.isValid()) {
        return (0, _moment['default'])(endDate.year() + '-' + 5 + '-' + 31, "YYYY-MM-DD");
      } else {
        return null;
      }
    }
  });
});