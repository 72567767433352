define('date-range-picker/pods/day-display/component', ['exports', 'date-range-picker/pods/day-display/template'], function (exports, _dateRangePickerPodsDayDisplayTemplate) {
  'use strict';

  var computed = Ember.computed;

  exports['default'] = Ember.Component.extend({
    classNameBindings: ['isSelected:dp-selected', 'inRange:dp-in-range', 'otherMonth:dp-other-month', 'isStartSelection:dp-start', 'isEndSelection:dp-end'],
    classNames: ['dp-day'],
    layout: _dateRangePickerPodsDayDisplayTemplate['default'],
    attributeBindings: ['tabindex'],
    tabindex: -1,

    click: function click() {
      this.sendAction('daySelected', this.get('day').clone());
    },

    inRange: computed('day', 'selectionStart', 'selectionEnd', function () {
      var _getProperties = this.getProperties('day', 'selectionStart', 'selectionEnd'),
          day = _getProperties.day,
          selectionStart = _getProperties.selectionStart,
          selectionEnd = _getProperties.selectionEnd;

      return day.isAfter(selectionStart) && day.isBefore(selectionEnd);
    }),

    isEndSelection: computed('day', 'selectionEnd', function () {
      var _getProperties2 = this.getProperties('day', 'selectionEnd'),
          day = _getProperties2.day,
          selectionEnd = _getProperties2.selectionEnd;

      return day.isSame(selectionEnd, 'day');
    }),

    isStartSelection: computed('day', 'selectionStart', function () {
      var _getProperties3 = this.getProperties('day', 'selectionStart'),
          day = _getProperties3.day,
          selectionStart = _getProperties3.selectionStart;

      return day.isSame(selectionStart, 'day');
    }),

    isSelected: computed('day', 'selectionStart', 'selectionEnd', function () {
      var _getProperties4 = this.getProperties('day', 'selectionStart', 'selectionEnd'),
          day = _getProperties4.day,
          selectionStart = _getProperties4.selectionStart,
          selectionEnd = _getProperties4.selectionEnd;

      return day.isSame(selectionStart, 'day') || day.isSame(selectionEnd, 'day');
    }),

    otherMonth: computed('day', 'month', function () {
      return this.get('day').month() !== this.get('month').month();
    })
  });
});