define("date-range-picker/pods/date-range-picker/component", ["exports", "date-range-picker/pods/date-range-picker/template", "date-range-picker/mixins/picker", "date-range-picker/mixins/keyboard-hotkeys"], function (exports, _dateRangePickerPodsDateRangePickerTemplate, _dateRangePickerMixinsPicker, _dateRangePickerMixinsKeyboardHotkeys) {
  "use strict";

  var _slicedToArray = (function () {
    function sliceIterator(arr, i) {
      var _arr = [];var _n = true;var _d = false;var _e = undefined;try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;_e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }return _arr;
    }return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  })();

  var Component = Ember.Component;

  exports["default"] = Component.extend(_dateRangePickerMixinsPicker["default"], _dateRangePickerMixinsKeyboardHotkeys["default"], {
    classNames: ['dp-date-range-picker'],
    mask: "9[9]/9[9]/99[99]—9[9]/9[9]/99[99]",
    presets: Ember.A(),
    layout: _dateRangePickerPodsDateRangePickerTemplate["default"],
    cancelSelected: false,
    applySelected: false,

    selectedPresetIndex: function selectedPresetIndex() {
      var sets = this.get('presets');
      var index = sets.findIndex(function (preset) {
        return preset.isSelected;
      });
      if (!index || index < 0) {
        sets.forEach(function (preset) {
          Ember.set(preset, 'isSelected', false);
        });
      }
      return index;
    },
    selectedIndexAndCurrentPreset: function selectedIndexAndCurrentPreset() {
      var selectedIndex = this.selectedPresetIndex();
      var currentPreset = this.get('presets')[selectedIndex];
      if (currentPreset) {
        Ember.set(currentPreset, "isSelected", false);
      }
      return [selectedIndex, currentPreset];
    },
    incrementSelectionIndex: function incrementSelectionIndex(currentPreset, currentIndex) {
      if (currentIndex < 0 && !this.get('cancelSelected') && !this.get('applySelected')) {
        // just opened window
        this.set('cancelSelected', false);
        this.set('applySelected', false);
        return 0;
      } else if (currentPreset && currentIndex < this.get('presets').length - 1) {
        // currently in the middle of preset selection
        this.set('cancelSelected', false);
        this.set('applySelected', false);
        return currentIndex + 1;
      } else if (currentIndex >= this.get('presets').length - 1 && !this.get('cancelSelected') && !this.get('applySelected')) {
        // at end of presets
        this.set('cancelSelected', true);
        this.set('applySelected', false);
        return null;
      } else if (this.get('cancelSelected')) {
        // cancel is selected
        this.set('cancelSelected', false);
        this.set('applySelected', true);
        return null;
      } else {
        // default state
        this.set('cancelSelected', false);
        this.set('applySelected', false);
        return 0;
      }
    },
    onTriggerArrowDown: function onTriggerArrowDown() {
      var _selectedIndexAndCurr = this.selectedIndexAndCurrentPreset(),
          _selectedIndexAndCurr2 = _slicedToArray(_selectedIndexAndCurr, 2),
          selectedIndex = _selectedIndexAndCurr2[0],
          currentPreset = _selectedIndexAndCurr2[1];

      var nextIndex = this.incrementSelectionIndex(currentPreset, selectedIndex);
      if (nextIndex !== null) {
        var nextPreset = this.get('presets')[nextIndex];
        this.clearSelection();
        Ember.set(nextPreset, "isSelected", true);
        this.send('applyPreset', nextPreset);
      }
    },
    decrementSelectionIndex: function decrementSelectionIndex(currentPreset, currentIndex) {
      if (currentIndex < 0 && !this.get('cancelSelected') && !this.get('applySelected')) {
        // just opened window
        this.set('cancelSelected', false);
        this.set('applySelected', true);
        return null;
      } else if (this.get('applySelected')) {
        // apply is selected
        this.set('cancelSelected', true);
        this.set('applySelected', false);
        return null;
      } else if (this.get('cancelSelected')) {
        // cancel is selected
        this.set('cancelSelected', false);
        this.set('applySelected', false);
        return this.get('presets').length - 1;
      } else if (currentPreset && currentIndex > 0) {
        // currently in the middle of preset selection
        this.set('cancelSelected', false);
        this.set('applySelected', false);
        return currentIndex - 1;
      } else {
        // default state
        this.set('cancelSelected', false);
        this.set('applySelected', true);
        return null;
      }
    },
    onTriggerArrowUp: function onTriggerArrowUp() {
      var _selectedIndexAndCurr3 = this.selectedIndexAndCurrentPreset(),
          _selectedIndexAndCurr4 = _slicedToArray(_selectedIndexAndCurr3, 2),
          selectedIndex = _selectedIndexAndCurr4[0],
          currentPreset = _selectedIndexAndCurr4[1];

      var nextIndex = this.decrementSelectionIndex(currentPreset, selectedIndex);
      if (nextIndex !== null) {
        var nextPreset = this.get('presets')[nextIndex];
        this.clearSelection();
        Ember.set(nextPreset, "isSelected", true);
        this.send('applyPreset', nextPreset);
      }
    },
    clearSelection: function clearSelection() {
      this.get('presets').forEach(function (preset) {
        Ember.set(preset, "isSelected", false);
      });
    },

    actions: {
      applyPreset: function applyPreset(preset) {
        this.send('startSelected', preset.startDate);
        this.send('endSelected', preset.endDate);
      },
      cancel: function cancel() {
        this.clearSelection();
        this._super();
      },
      apply: function apply() {
        this.clearSelection();
        this._super();
      },
      endSelected: function endSelected(day) {
        var startDate = this.get('startDate');

        if (day.isBefore(startDate)) {
          this.set('startDate', day);
        }

        this.set('endDate', day);
        this.updateStartAndEndMonth();
      },
      nextEndMonth: function nextEndMonth() {
        this.set('endMonth', this.get('endMonth').clone().add(1, 'month').startOf('month'));
      },
      nextStartMonth: function nextStartMonth() {
        this.set('startMonth', this.get('startMonth').clone().add(1, 'month').startOf('month'));
      },
      prevEndMonth: function prevEndMonth() {
        this.set('endMonth', this.get('endMonth').clone().subtract(1, 'month').startOf('month'));
      },
      prevStartMonth: function prevStartMonth() {
        this.set('startMonth', this.get('startMonth').clone().subtract(1, 'month').startOf('month'));
      },
      startSelected: function startSelected(day) {
        var endDate = this.get('endDate');

        if (day.isAfter(endDate)) {
          this.set('endDate', day);
        }

        if (!endDate) {
          this.set('endDate', day);
        }

        this.set('startDate', day);
        this.updateStartAndEndMonth();
      }
    }
  });
});