define('date-range-picker/mixins/expanded-validators', ['exports'], function (exports) {
  'use strict';

  var observer = Ember.observer;

  exports['default'] = Ember.Mixin.create({
    monthIsExpanded: false,
    yearIsExpanded: false,

    _monthIsExpandedValidator: observer('monthIsExpanded', function () {
      if (this.get('monthIsExpanded') === true) {
        this.set('yearIsExpanded', false);
      }
    }),

    _yearIsExpandedValidator: observer('yearIsExpanded', function () {
      if (this.get('yearIsExpanded') === true) {
        this.set('monthIsExpanded', false);
      }
    }),

    actions: {
      toggleMonthExpanded: function toggleMonthExpanded() {
        this.toggleProperty('monthIsExpanded');
      },
      toggleYearExpanded: function toggleYearExpanded() {
        this.toggleProperty('yearIsExpanded');
      }
    }
  });
});