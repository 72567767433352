define("ember-basic-dropdown/templates/components/basic-dropdown/content", ["exports"], function (exports) {
  "use strict";

  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.7.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 4,
                  "column": 6
                },
                "end": {
                  "line": 6,
                  "column": 6
                }
              },
              "moduleName": "modules/ember-basic-dropdown/templates/components/basic-dropdown/content.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "ember-basic-dropdown-overlay");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.7.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 7,
                  "column": 6
                },
                "end": {
                  "line": 15,
                  "column": 6
                }
              },
              "moduleName": "modules/ember-basic-dropdown/templates/components/basic-dropdown/content.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["content", "yield", ["loc", [null, [14, 8], [14, 17]]], 0, 0, 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.7.0",
            "loc": {
              "source": null,
              "start": {
                "line": 3,
                "column": 4
              },
              "end": {
                "line": 16,
                "column": 4
              }
            },
            "moduleName": "modules/ember-basic-dropdown/templates/components/basic-dropdown/content.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "overlay", ["loc", [null, [4, 12], [4, 19]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [4, 6], [6, 13]]]], ["block", "basic-dropdown/content-element", [], ["tagName", ["subexpr", "@mut", [["get", "_contentTagName", ["loc", [null, [8, 16], [8, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "id", ["subexpr", "@mut", [["get", "dropdownId", ["loc", [null, [9, 11], [9, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "class", ["subexpr", "concat", ["ember-basic-dropdown-content ", ["get", "class", ["loc", [null, [10, 54], [10, 59]]], 0, 0, 0, 0], " ", ["get", "defaultClass", ["loc", [null, [10, 64], [10, 76]]], 0, 0, 0, 0], " ", ["subexpr", "if", [["get", "renderInPlace", ["loc", [null, [10, 85], [10, 98]]], 0, 0, 0, 0], "ember-basic-dropdown-content--in-place "], [], ["loc", [null, [10, 81], [10, 141]]], 0, 0], ["subexpr", "if", [["get", "hPosition", ["loc", [null, [10, 146], [10, 155]]], 0, 0, 0, 0], ["subexpr", "concat", ["ember-basic-dropdown-content--", ["get", "hPosition", ["loc", [null, [10, 197], [10, 206]]], 0, 0, 0, 0]], [], ["loc", [null, [10, 156], [10, 207]]], 0, 0]], [], ["loc", [null, [10, 142], [10, 208]]], 0, 0], " ", ["subexpr", "if", [["get", "vPosition", ["loc", [null, [10, 217], [10, 226]]], 0, 0, 0, 0], ["subexpr", "concat", ["ember-basic-dropdown-content--", ["get", "vPosition", ["loc", [null, [11, 8], [11, 17]]], 0, 0, 0, 0]], [], ["loc", [null, [10, 227], [11, 18]]], 0, 0]], [], ["loc", [null, [10, 213], [11, 19]]], 0, 0], " ", ["get", "animationClass", ["loc", [null, [11, 24], [11, 38]]], 0, 0, 0, 0]], [], ["loc", [null, [10, 14], [11, 39]]], 0, 0], "style", ["subexpr", "@mut", [["get", "style", ["loc", [null, [12, 14], [12, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "dir", ["subexpr", "@mut", [["get", "dir", ["loc", [null, [13, 12], [13, 15]]], 0, 0, 0, 0]], [], [], 0, 0]], 1, null, ["loc", [null, [7, 6], [15, 41]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.7.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 18,
                    "column": 8
                  },
                  "end": {
                    "line": 20,
                    "column": 8
                  }
                },
                "moduleName": "modules/ember-basic-dropdown/templates/components/basic-dropdown/content.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "ember-basic-dropdown-overlay");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "revision": "Ember@2.7.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 21,
                    "column": 8
                  },
                  "end": {
                    "line": 29,
                    "column": 8
                  }
                },
                "moduleName": "modules/ember-basic-dropdown/templates/components/basic-dropdown/content.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["content", "yield", ["loc", [null, [28, 10], [28, 19]]], 0, 0, 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.7.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 17,
                  "column": 6
                },
                "end": {
                  "line": 30,
                  "column": 6
                }
              },
              "moduleName": "modules/ember-basic-dropdown/templates/components/basic-dropdown/content.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "overlay", ["loc", [null, [18, 14], [18, 21]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [18, 8], [20, 15]]]], ["block", "basic-dropdown/content-element", [], ["tagName", ["subexpr", "@mut", [["get", "_contentTagName", ["loc", [null, [22, 18], [22, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "id", ["subexpr", "@mut", [["get", "dropdownId", ["loc", [null, [23, 13], [23, 23]]], 0, 0, 0, 0]], [], [], 0, 0], "class", ["subexpr", "concat", ["ember-basic-dropdown-content ", ["get", "class", ["loc", [null, [24, 56], [24, 61]]], 0, 0, 0, 0], " ", ["get", "defaultClass", ["loc", [null, [24, 66], [24, 78]]], 0, 0, 0, 0], " ", ["subexpr", "if", [["get", "renderInPlace", ["loc", [null, [24, 87], [24, 100]]], 0, 0, 0, 0], "ember-basic-dropdown-content--in-place "], [], ["loc", [null, [24, 83], [24, 143]]], 0, 0], ["subexpr", "if", [["get", "hPosition", ["loc", [null, [24, 148], [24, 157]]], 0, 0, 0, 0], ["subexpr", "concat", ["ember-basic-dropdown-content--", ["get", "hPosition", ["loc", [null, [24, 199], [24, 208]]], 0, 0, 0, 0]], [], ["loc", [null, [24, 158], [24, 209]]], 0, 0]], [], ["loc", [null, [24, 144], [24, 210]]], 0, 0], " ", ["subexpr", "if", [["get", "vPosition", ["loc", [null, [24, 219], [24, 228]]], 0, 0, 0, 0], ["subexpr", "concat", ["ember-basic-dropdown-content--", ["get", "vPosition", ["loc", [null, [25, 10], [25, 19]]], 0, 0, 0, 0]], [], ["loc", [null, [24, 229], [25, 20]]], 0, 0]], [], ["loc", [null, [24, 215], [25, 21]]], 0, 0], " ", ["get", "animationClass", ["loc", [null, [25, 26], [25, 40]]], 0, 0, 0, 0]], [], ["loc", [null, [24, 16], [25, 41]]], 0, 0], "style", ["subexpr", "@mut", [["get", "style", ["loc", [null, [26, 16], [26, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "dir", ["subexpr", "@mut", [["get", "dir", ["loc", [null, [27, 14], [27, 17]]], 0, 0, 0, 0]], [], [], 0, 0]], 1, null, ["loc", [null, [21, 8], [29, 43]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.7.0",
            "loc": {
              "source": null,
              "start": {
                "line": 16,
                "column": 4
              },
              "end": {
                "line": 31,
                "column": 4
              }
            },
            "moduleName": "modules/ember-basic-dropdown/templates/components/basic-dropdown/content.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "-in-element", [["get", "destinationElement", ["loc", [null, [17, 21], [17, 39]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [17, 6], [30, 22]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.7.0",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 33,
              "column": 0
            }
          },
          "moduleName": "modules/ember-basic-dropdown/templates/components/basic-dropdown/content.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "ember-basic-dropdown-content-wormhole-origin");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["block", "if", [["get", "renderInPlace", ["loc", [null, [3, 10], [3, 23]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [3, 4], [31, 11]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.0",
          "loc": {
            "source": null,
            "start": {
              "line": 33,
              "column": 0
            },
            "end": {
              "line": 35,
              "column": 0
            }
          },
          "moduleName": "modules/ember-basic-dropdown/templates/components/basic-dropdown/content.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "ember-basic-dropdown-content-placeholder");
          dom.setAttribute(el1, "style", "display: none;");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element0, 'id');
          return morphs;
        },
        statements: [["attribute", "id", ["get", "dropdownId", ["loc", [null, [34, 12], [34, 22]]], 0, 0, 0, 0], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.7.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 35,
            "column": 7
          }
        },
        "moduleName": "modules/ember-basic-dropdown/templates/components/basic-dropdown/content.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "dropdown.isOpen", ["loc", [null, [1, 6], [1, 21]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [1, 0], [35, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});