define('date-range-picker/pods/presets-picker/component', ['exports', 'date-range-picker/pods/presets-picker/template'], function (exports, _dateRangePickerPodsPresetsPickerTemplate) {
  'use strict';

  exports['default'] = Ember.Component.extend({
    tabIndex: -1,
    layout: _dateRangePickerPodsPresetsPickerTemplate['default'],

    actions: {
      applyPreset: function applyPreset(preset) {
        this.get('presets').forEach(function (preset) {
          Ember.set(preset, "isSelected", false);
        });
        this.sendAction('startSelected', preset.startDate);
        this.sendAction('endSelected', preset.endDate);
        Ember.set(preset, "isSelected", true);
      }
    }
  });
});