define('date-range-picker/pods/range-input-mask/component', ['exports', 'ember-inputmask/components/input-mask'], function (exports, _emberInputmaskComponentsInputMask) {
  'use strict';

  exports['default'] = _emberInputmaskComponentsInputMask['default'].extend({
    // Default options
    greedyMask: false,
    skipOptionalPartCharacter: "-",
    clearIncomplete: true,

    updateMask: function updateMask() {
      this.setProperties({
        'options.skipOptionalPartCharacter': this.get('skipOptionalPartCharacter')
      });

      this._super();
    }
  });
});