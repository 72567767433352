define('date-range-picker/pods/month-display/component', ['exports', 'date-range-picker/pods/month-display/template', 'date-range-picker/helpers/range', 'moment'], function (exports, _dateRangePickerPodsMonthDisplayTemplate, _dateRangePickerHelpersRange, _moment) {
  'use strict';

  exports['default'] = Ember.Component.extend({
    allMonths: (0, _dateRangePickerHelpersRange.range)(1, 13),
    isExpanded: false,
    layout: _dateRangePickerPodsMonthDisplayTemplate['default'],
    tagName: "span",

    didRender: function didRender() {
      var _this = this;

      if (this.get('isExpanded')) {
        Ember.run.next(this, function () {
          var $container = _this.$('.dp-month-body');
          var month = _this.get('month');
          if (!month) {
            return;
          }

          var $scrollTo = _this.$('button.dp-month-option:contains("' + month.format('MMM') + '")');
          if ($container && $container.length && $scrollTo && $scrollTo.length) {
            $container.scrollTop($scrollTo.offset().top - $container.offset().top + $container.scrollTop());
            $container.animate({
              scrollTop: $scrollTo.offset().top - $container.offset().top + $container.scrollTop()
            }, 0);
          }
        });
      }
    },

    actions: {
      setMonth: function setMonth(month) {
        var oldMonth = this.get('month');
        if (!oldMonth) {
          oldMonth = (0, _moment['default'])();
        }
        oldMonth = oldMonth.clone().month(month);

        if (this.get('endOfMonth')) {
          this.set('month', oldMonth.endOf('month'));
        } else {
          this.set('month', oldMonth.startOf('month'));
        }

        if (this.get('monthWasSelected')) {
          this.sendAction('monthWasSelected');
        }
      },
      toggleIsExpanded: function toggleIsExpanded() {
        this.toggleProperty('isExpanded');
      }
    }
  });
});