define('date-range-picker/helpers/friendly-month', ['exports'], function (exports) {
  'use strict';

  exports.friendlyMonth = friendlyMonth;

  var MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  function friendlyMonth(monthIndex) {
    return MONTHS[monthIndex - 1];
  }

  exports['default'] = Ember.Helper.helper(friendlyMonth);
});