define('ember-keyboard/mixins/activate-keyboard-on-insert', ['exports'], function (exports) {
  'use strict';

  var Mixin = Ember.Mixin,
      on = Ember.on,
      set = Ember.set;

  exports['default'] = Mixin.create({
    activateKeyboardWhenPresent: on('didInsertElement', function () {
      set(this, 'keyboardActivated', true);
    })
  });
});